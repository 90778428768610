// Generated by Framer (78a4586)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mc960lazw", "mejMh4_HS", "RxWE9q5Lt"];

const serializationHash = "framer-AIOcT"

const variantClassNames = {mc960lazw: "framer-v-usiiq6", mejMh4_HS: "framer-v-98xd84", RxWE9q5Lt: "framer-v-1b4zub3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const transition2 = {delay: 0, duration: 0.7, ease: [0.12, 0.23, 0.5, 1], type: "tween"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Available-2": "mejMh4_HS", Available: "mc960lazw", Unavailable: "RxWE9q5Lt"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mc960lazw"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mc960lazw", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear12v26te = activeVariantCallback(async (...args) => {
await delay(() => setVariant("mejMh4_HS"), 1000)
})

const onAppearvce4od = activeVariantCallback(async (...args) => {
await delay(() => setVariant("mc960lazw"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear12v26te, mejMh4_HS: onAppearvce4od, RxWE9q5Lt: undefined})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({mejMh4_HS: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-usiiq6", className, classNames)} data-framer-name={"Available"} data-highlight layoutDependency={layoutDependency} layoutId={"mc960lazw"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({mejMh4_HS: {"data-framer-name": "Available-2"}, RxWE9q5Lt: {"data-framer-name": "Unavailable", "data-highlight": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1nee6xj"} data-framer-name={"Pulser"} layoutDependency={layoutDependency} layoutId={"xufwWwapJ"}><motion.div className={"framer-1grr3ez"} data-framer-name={"Pulsing"} layoutDependency={layoutDependency} layoutId={"cCT736Psw"} style={{backgroundColor: "rgb(49, 238, 51)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, opacity: 0.5}} transformTemplate={transformTemplate1} variants={{mejMh4_HS: {borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, opacity: 0}, RxWE9q5Lt: {backgroundColor: "rgb(237, 49, 49)"}}}/><motion.div className={"framer-13hh77y"} data-framer-name={"Solid"} layoutDependency={layoutDependency} layoutId={"er2tb0UdK"} style={{backgroundColor: "rgb(49, 238, 51)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, boxShadow: "0px 0px 20px 0px rgba(49, 238, 51, 0.5)"}} variants={{mejMh4_HS: {borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}, RxWE9q5Lt: {backgroundColor: "rgb(237, 49, 49)", boxShadow: "0px 0px 20px 0px hsla(0, 84%, 56%, 0.5)"}}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AIOcT.framer-sbiuqz, .framer-AIOcT .framer-sbiuqz { display: block; }", ".framer-AIOcT.framer-usiiq6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 2px; position: relative; width: min-content; }", ".framer-AIOcT .framer-1nee6xj { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 6px); overflow: visible; position: relative; width: 6px; }", ".framer-AIOcT .framer-1grr3ez { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 6px); left: 50%; overflow: hidden; position: absolute; top: 50%; width: 6px; will-change: var(--framer-will-change-override, transform); }", ".framer-AIOcT .framer-13hh77y { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-AIOcT.framer-usiiq6 { gap: 0px; } .framer-AIOcT.framer-usiiq6 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-AIOcT.framer-usiiq6 > :first-child { margin-left: 0px; } .framer-AIOcT.framer-usiiq6 > :last-child { margin-right: 0px; } }", ".framer-AIOcT.framer-v-98xd84 .framer-1grr3ez { height: var(--framer-aspect-ratio-supported, 48px); width: 48px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 10
 * @framerIntrinsicWidth 10
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"mejMh4_HS":{"layout":["auto","auto"]},"RxWE9q5Lt":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergQDW_Sooh: React.ComponentType<Props> = withCSS(Component, css, "framer-AIOcT") as typeof Component;
export default FramergQDW_Sooh;

FramergQDW_Sooh.displayName = "Availability";

FramergQDW_Sooh.defaultProps = {height: 10, width: 10};

addPropertyControls(FramergQDW_Sooh, {variant: {options: ["mc960lazw", "mejMh4_HS", "RxWE9q5Lt"], optionTitles: ["Available", "Available-2", "Unavailable"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramergQDW_Sooh, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})